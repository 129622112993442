Sage.common = {
  init: function() {
    // JavaScript to be fired on all pages

    // Age Check Cookies
    if (Cookies.get('old_enough') !== 'true') {
      $('#age-please').modal({
        keyboard: false,
        backdrop: 'static'
      });
    }

    $('#age-please .yes button').click(function() {
      Cookies.set('old_enough', 'true', { expires: 31 });
    });

    $('#age-please .no button').click(function() {
      $('#comeBackLater').addClass('show');
      $('#ask-age').addClass('conceal');
    });

    // Popup Cookies
    var $popUpModal = $('.pop-up-modal');

    function setPopUpCookie($modal) {
      $id = $modal.attr('data-id');

      Cookies.set('popUpDismissed', $id, { expires: 30 });
    }

    $popUpModal.on('hidden.bs.modal', function () {
      setPopUpCookie($popUpModal);
    });

    $popUpModal.find('.btn').on('click', function() {
      setPopUpCookie($popUpModal);
    });

    if (Cookies.get('popUpDismissed') === $popUpModal.attr('data-id')) {
      $popUpModal.remove();
    } else {
      $popUpModal.modal();
    }



    $('#toggleMenu').click(function(e) {
      e.preventDefault();
      $('#toggleMenu').toggleClass('menu-active');
      $('.mobile-menu').toggleClass('menu-open');
      $('body').toggleClass('menu-active');
    });

    $('#scrollPrompt').on('click', function(e) {
      $('html, body').animate({
          scrollTop: $('#bannerWrap').offset().top
        }, 600
      );
    });

    var inviewFooter = new Waypoint.Inview({
      element: $('#footerTransition')[0],
      enter: function(direction) {
        $('#footerTransition').addClass('animate');
      },
      entered: function(direction) {},
      exit: function(direction) {},
      exited: function(direction) {}
    });

    var myLazyLoad = new LazyLoad({
      elements_selector: '.lazy'
    });

    $('.image-loading').imagesLoaded(function() {
      $('.image-loading').addClass('image-loaded').removeClass('image-loading');
    });

    // MAPS!
    const $map = $('.google-map');

    // https://gist.github.com/nmsdvid/8807205
    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    function debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
          timeout = null;
          if (!immediate) {
            func.apply(context, args);
          }
        }, wait);
        if (immediate && !timeout) {
          func.apply(context, args);
        }
      };
    }

    if ($map.length > 0) {
      if (!(typeof window.google === 'object' && window.google.maps)) {
        console.error('Google Maps API is not loaded, cannot display map.');
      } else {
        let markers = [];
        let initialLoad = true;

        if (window.beerMapData !== undefined && window.beerMapData.stockists) {
          markers = window.beerMapData.stockists;
        }

        // Generic BC Fallback
        let center = new window.google.maps.LatLng(52.054971, -121.628607);

        // Use the first marker as the initial centre
        if (markers.length > 0) {
          center = new window.google.maps.LatLng(
            markers[0].lat,
            markers[0].lng
          );
        }

        // Make the map
        const map = new window.google.maps.Map($map[0], {
          zoom: parseInt($map.data('zoom'), 10),
          center: center,
          fullscreenControl: false,
          mapTypeControl: false,
          rotateControl: false,
          streetViewControl: false,
          scaleControl: false,
          styles: mapStyle,
        });

        // Make the infowindow for this map
        const infoWindow = new window.google.maps.InfoWindow({
          maxWidth: 260
        });

        map.addListener('click', function() {
          infoWindow.close();
        });

        const handleLocationError = function (GeolocationPositionError) {
          let geoLocationError = 'Error: Your browser doesn\'t support geolocation.';

          switch (GeolocationPositionError.code) {
            case 1:
              geoLocationError = 'Error: Permission error getting location.';
              break;
            case 2:
              geoLocationError = 'Error: Unable to determine location.';
              break;
            case 3:
              geoLocationError = 'Error: Location request timed out.';
              break;
          }

          infoWindow.setPosition(map.getCenter());
          infoWindow.setContent(geoLocationError);
          infoWindow.open(map);

          $('.section-beer-finder').removeClass('loading');
        };

        const showNearbyLocations = function (position) {
          const userLocation = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);

          // Zoom map to user's location
          map.panTo(userLocation);
          map.setZoom(13); // ~18km?

          // The 'idle' event will take care of list filtering

          // Open closest marker
          // var closestMarker = markers.reduce(function (prev, curr) {
          //     var cpos = window.google.maps.geometry.spherical.computeDistanceBetween(userLocation, curr.position);
          //     var ppos = window.google.maps.geometry.spherical.computeDistanceBetween(userLocation, prev.position);

          //     return cpos < ppos ? curr : prev;
          // });

          // window.google.maps.event.trigger(closestMarker, 'click');

          $('.section-beer-finder').removeClass('loading');
        };

        $('.beer-finder-geo').on('click', function() {
          // Try HTML5 geolocation.
          if (navigator.geolocation) {
            $('.section-beer-finder').addClass('loading');

            navigator.geolocation.getCurrentPosition(
              showNearbyLocations,
              handleLocationError
            );
          } else {
            // Browser doesn't support Geolocation
            handleLocationError({ code: 0 });
          }
        });

        const markerSVG = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 42"><path d="M12.5 1A11.5 11.5 0 0124 12.5C24 18.9 12 41 12 41S1 18.9 1 12.5A11.5 11.5 0 0112.5 1z" fill="{MARKER_COLOUR}" stroke="#fff" fill-rule="evenodd"/><circle cx="12.5" cy="12.5" r="4.5" fill="#fff"/></svg>';

        const infoWindowContent = function(marker) {
          let markerContent = '<div class="stockist infowindow">';

          markerContent += '<div class="title">';
          markerContent += marker.content.website ? '<a href="' + marker.content.website + '" target="_blank" rel="noopener">' + marker.content.title + ' <i class="fa fa-link" aria-hidden="true"></i></a>' : marker.content.title;
          markerContent += '</div>';
          markerContent += '<div class="address">' + marker.content.address;
          markerContent += ' <a href="https://www.google.com/maps/dir/?api=1&destination='+ encodeURIComponent(marker.content.address) +'" target="_blank"><i class="fa fa-location-arrow"></i> Get Directions</a>';
          markerContent += '</div>'; // Close .address
          if (marker.content.phone) {
            markerContent += '<div class="phone">' + marker.content.phone + '</div>';
          }

          $.each(marker.content.terms, function(filter, value) {
            markerContent += '<div class="terms">' + '<span class="term-name">' + filter + '</span> ' + value + '</div>';
          });

          markerContent += '</div>'; // Close .stockist

          return markerContent;
        };

        const showInfoWindow = function(event, marker) {
          if (!marker) {
            marker = this;
          }

          infoWindow.setContent(infoWindowContent(marker));
          infoWindow.open(map, marker);
        };

        // Set up the list
        const stockistList = new List(
          'stockist-list',
          {
            valueNames: [{ data: ['id'] }, 'title', 'address', 'phone', 'storetype', { name: 'markerColour', attr: 'style' }, {name: 'directions', attr: 'href'}],
            item: '<li class="stockist"><div class="store-type-container markerColour"><span class="marker"></span><span class="storetype"></span></div><h3 class="title"></h3><div class="address"></div><div class="phone"></div><div class="links"><a href target="_blank" class="directions"><i class="fa fa-location-arrow"></i> Get directions</a></div></li>',
          }
        );

        // Set up clustering
        const imageSizes = [53, 56, 78, 90];
        let clusterStyles = [];

        for (let i = 0; i < imageSizes.length; i++) {
          const size = imageSizes[i];
          clusterStyles.push({
            url: window.beerMapData.imagePath + '/m' + (i + 1) + "@2x.png",
            height: size,
            width: size,
            fontWeight: "bold",
            fontFamily: "'futura-pt', Futura, 'Century Gothic', Arial, sans-serif",
            textSize: 20,
            textColor: "#fff",
          });
        }

        const markerCluster = new window.MarkerClusterer(
          map,
          [],
          {
            styles: clusterStyles,
            enableRetinaIcons: true,
            maxZoom: 16,
          }
        );

        // Map filtering
        const updateStockistsMap = function(list) {
          // Get all markers currently in the clusterer
          const clusterMarkers = markerCluster.getMarkers();

          // Collect just the matching markers
          const matchingMarkers = list.matchingItems.map(function(item) {
            return item.values().marker;
          });

          // Check if there's a difference between what we should be showing
          // and what we are showing
          const markersDiff = clusterMarkers.filter(function(element) {
            return ! matchingMarkers.includes(element);
          })
          .concat(matchingMarkers.filter(function(element) {
            return ! clusterMarkers.includes(element);
          }));

          // If things don't match, clear everything out and add what we should
          // be showing
          if (markersDiff.length) {
            // If no results, show the no results message
            if (!initialLoad) {
              if (matchingMarkers.length === 0) {
                $('.no-results').show();
              } else {
                $('.no-results').hide();
              }
            }

            // Destroy all clusters
            markerCluster.clearMarkers();

            // Create new markers of only the matching items
            markerCluster.addMarkers(matchingMarkers);
          }
        };

        const filterStockists = function() {
          // Collect all markers that are currently visible
          let inBoundsMarkers = [];
          let filterTerms = [];

          // Collect in bounds markers
          for (let i = markers.length, bounds = map.getBounds(); i--;) {
            if (bounds.contains(markers[i].getPosition())) {
              inBoundsMarkers.push(markers[i]);
            }
          }
          // Collect all set filters
          $('[data-filter-select]').each(function() {
            filterTerms.push($(this).children("option:selected").val());
          });

          // Filter out anything blank/missing/unset
          filterTerms = filterTerms.filter(Boolean);
          const noFiltersSet = filterTerms.length === 0;

          stockistList.filter(function(item) {
            const itemMarker = item.values().marker;
            const stockistFilterTerms = itemMarker.content.filterTerms;

            const doesMatchFilter = filterTerms.every(function(element, index, array) {
              return stockistFilterTerms.indexOf(element) > -1;
            });

            const isMarkerInBounds = inBoundsMarkers.includes(itemMarker);

            // Return if the marker is in bounds and it matches the filter,
            // or if there are no filters set
            return isMarkerInBounds && (doesMatchFilter || noFiltersSet);
          });

          // Remove loading in case it was set
          $('.section-beer-finder').removeClass('loading');
        };

        // Debounce the update event
        // const debouncedUpdateStockistsMap = debounce(updateStockistsMap, 250);

        stockistList.on('updated', updateStockistsMap);

        $('[data-filter-select]').on('change', function() {
          $('.section-beer-finder').addClass('loading');
          filterStockists();
        });
        map.addListener('idle', filterStockists);

        const processMarkers = function() {
          // Process markers
          $.each(markers, function(markerIndex) {
            let markerColour = '#EE7337';

            // See if any of this stockists filters have a colour override and set it
            $.each(markers[markerIndex].content.filterTerms, function(filterIndex, filterTerm) {
              if (window.beerMapData.filters[filterTerm] && window.beerMapData.filters[filterTerm].colour) {
                markerColour = window.beerMapData.filters[filterTerm].colour;
              }
            });

            const dynamicSvg = {
              url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(markerSVG.replace('{MARKER_COLOUR}', markerColour)),
              anchor: new google.maps.Point(12.5, 21),
              scaledSize: new google.maps.Size(25, 42),
            };

            markers[markerIndex] = new window.google.maps.Marker({
              position: new window.google.maps.LatLng(
                markers[markerIndex].lat,
                markers[markerIndex].lng
              ),
              // map: map, // Don't assign to map, will be using marker clusterer
              title: markers[markerIndex].content.title,
              content: markers[markerIndex].content,
              icon: dynamicSvg,
              markerColour: markerColour,
            });

            // Make infowindow go now
            markers[markerIndex].addListener('click', showInfoWindow);
          });

          // Add to clusterer
          markerCluster.addMarkers(markers);

          // Show all markers/clusters on map
          markerCluster.fitMapToMarkers();

          // Add to list
          stockistList.add(markers.map(function(marker) {
            let storetype = marker.content.filterTerms.map(function(filterTerm) {
              return filterTerm.startsWith('store-type') ? window.beerMapData.filters[filterTerm].name : null;
            }).filter(Boolean).join(', ');

            let title = marker.content.title;

            if ( marker.content.website ) {
              title = '<a href="' + marker.content.website + '" target="_blank" rel="noopener">' + marker.content.title + ' <i class="fa fa-link"></i></a>';
            }

            return {
              id: marker.content.id,
              title: title,
              address: marker.content.address,
              directions: 'https://www.google.com/maps/dir/?api=1&destination='+ encodeURIComponent(marker.content.address),
              storetype: storetype,
              marker: marker,
              markerColour: 'color: ' + marker.markerColour + ';',
            };
          }));

          // Read filters from URL query params
          var filterParams = deparam(window.location.search.slice(1));

          // Set filters if we can
          $.each(filterParams, function(filter, value) {
            if (filter.slice(0, 7) === 'filter-') {
              if (! Array.isArray(value)) {
                value = [value];
              }

              $.each(value, function(index, filterValue) {
                filterValue = filter.slice(7) + '-' + filterValue;
                let $filterInput = $('#' + filter);

                if ($filterInput) {
                  $filterInput.val(filterValue).change();
                }
              });
            }
          });

          // Click handler for list items
          $('#stockistList ul').on('click', function(event) {
            const target = event.target;

            if (target) {
              const $item = $(target).closest('li');
              let item = stockistList.get('id', $item.data('id'));

              if (item.length) {
                item = item[0];
                let marker = item.values().marker;

                showInfoWindow(null, marker);
                map.panTo(marker.position);
                map.setZoom(18); // ~18km?
              }
            }
          });

          // It's done!
          $('.section-beer-finder').removeClass('loading');
          initialLoad = false;
        };

        // We're loading
        $('.section-beer-finder').addClass('loading');

        if (markers.length > 0) {
          // We got markers on load, giddy up
          processMarkers();
        } else {
          // No markers, AJAX load them
          $.ajax({
            url: window.beerMapData.adminAjax,
            type: 'get',
            data: {
              action: 'driftwood_stockists',
            },
            success: function(response) {
              if (response.success !== undefined && response.success) {
                markers = response.data;
                processMarkers();
              } else {
                console.log('something went wrong');
              }
            },
            error: function() {
              console.log('something else went wrong');
            }
          });
        }
      }
    }
  },
  finalize: function() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};

Sage.home = {
  init: function() {
    // JavaScript to be fired on the home page
    var navToggle = new Waypoint({
      element: document.getElementById('bannerWrap'),
      handler: function(direction) {
        if (direction === 'down') {
          $('.banner').addClass('sticky');
        } else {
          $('.banner').removeClass('sticky');
        }
      }
    });

    function isIE () {
      var myNav = navigator.userAgent.toLowerCase();
      return (myNav.indexOf('msie') !== -1) ? parseInt(myNav.split('msie')[1]) : false;
    }

    var isIEOld = isIE() && isIE() < 9;

    if ($(window).width() >= 768 && !isIEOld && $('#videoLoop').find('video').length > 0) {
      $('.video-loading').addClass('complete');
    } else {
      $('#videoLoop').find('video').remove();
      $('#sectionVideo').hide();
      $('body').addClass('no-video');
      $('.banner').addClass('sticky');
    }

    var verticalCenter = function() {
      // Apply Padding to Top and Bottom of
      // class .vertical-center
      $('.vertical-center').each(function() {
        var windowHeight = $(window).innerHeight();
        var navHeight = $('.banner').height();
        var intendedHeight = windowHeight - navHeight;

        if ($(this).parents().hasClass('section-timeline')) {
          var timelineHeight = $('.section-timeline-tracker').height();
          intendedHeight = windowHeight - navHeight - timelineHeight;
        }

        var containerHeight = $(this).height();
        var calcPadding = (intendedHeight - containerHeight) / 2;

        if (containerHeight < intendedHeight && $(window).width() >= 768) {
          $(this).css({
            paddingTop: calcPadding,
            paddingBottom: calcPadding
          });
        } else if (containerHeight > intendedHeight && $(window).width() >= 768) {
          // console.log('container does not fit');
        } else {
          $(this).removeAttr('style');
        }
      });
    };

    verticalCenter();

    var setGalleryHeight = function(targetGallery) {
      var windowHeight = $(window).innerHeight();
      var navHeight = $('.banner').height();
      var intendedHeight = windowHeight - navHeight;

      if ( targetGallery === '.timeline-gallery-container' ) {
        var timelineHeight = $('.section-timeline-tracker').height();
        intendedHeight = windowHeight - navHeight - timelineHeight;
        //console.log(timelineHeight);
      }

      if ($(window).width() >= 768 || targetGallery === '.timeline-gallery-container') {
        $(targetGallery).css({
          height: intendedHeight
        });
      } else {
        $(targetGallery).removeAttr('style');
      }
    };

    var initWindowWidth = $(window).width();

    $(window).on('resize', function() {
      if ($(window).width() !== initWindowWidth) {
        verticalCenter();
        setGalleryHeight('.about-gallery-container');
        setGalleryHeight('.timeline-gallery-container');
        initWindowWidth = $(window).width();
      }
    });

    setGalleryHeight('.about-gallery-container');

    var $gallery = $('.about-gallery').flickity({
      cellAlign: 'left',
      setGallerySize: false,
      watchCSS: true,
      //imagesLoaded: true,
      pageDots: false,
      lazyLoad: true,
      arrowShape: 'M18.5,50L79.9,0l5.6,5.9L47.8,50,85.5,94.1,79.9,100Z'
    });

    $('.button-next').on('click', function() {
      $gallery.flickity('next');
    });

    var flkty = $gallery.data('flickity');

    // load all lazy load images
    if ( !flkty.isActive ) {
      $gallery.find('[data-flickity-lazyload]').each( function( i, elem  ) {
        var $elem = $(elem);
        $elem.attr( 'src', $elem.attr('data-flickity-lazyload') );
        $elem.addClass('flickity-lazyloaded');
        elem.removeAttribute('data-flickity-lazyload');
      });
    }

    var $timelineNav = $('.timeline-nav').flickity({
      asNavFor: '.timeline-gallery',
      prevNextButtons: false,
      pageDots: false
    });

    setGalleryHeight('.timeline-gallery-container');

    var $timeline = $('.timeline-gallery').flickity({
      //imagesLoaded: true,
      pageDots: false,
      prevNextButtons: true,
      draggable: true,
      setGallerySize: false,
      lazyLoad: true,
      arrowShape: 'M18.5,50L79.9,0l5.6,5.9L47.8,50,85.5,94.1,79.9,100Z'
    });


  },
  finalize: function() {
    // JavaScript to be fired on the home page, after the init JS
  }
};

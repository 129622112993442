Sage.post_type_archive_beer = {
  init: function() {
    $('.beers-tab-nav a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      //e.target // newly activated tab
      //e.relatedTarget // previous active tab
      $('body, html').animate({
        scrollTop: $("#beerArchive").offset().top - 80,
        }, 600
      );
    });

    var setActiveTab = function(href, tabHash) {
      href = href || false;
      tabHash = tabHash || false;

      const $beerNav = $('.beer-cat-nav');

      if ( tabHash !== false ) {
        //tabHash.replace( /([a-z])([A-Z])/g, "$1 $2");
        href = $beerNav.find("a[data-hash='" + tabHash +"']").attr('href');
      }

      //set all nav tabs to inactive
      $beerNav.find('a[data-toggle="tab"]').closest('li').removeClass('active');

      //get all nav tabs matching the href and set to active
      $beerNav.find('a[data-toggle="tab"][href="'+href+'"]').closest('li').addClass('active');

      //active tab
      $('#beerArchive .tab-pane').removeClass('active in');
      $('#beerArchive .tab-pane' + href).addClass('active in');
    };

    $('.beer-cat-nav a[data-toggle="tab"]').click(function (e) {
      var tabHash = $(this).data('hash');

      window.location.hash = tabHash;

      if ($(window).width() >= 768) {
        //get selected href and activate tab
        setActiveTab($(this).attr('href'));
        Waypoint.refreshAll();
      }
    });

    if (window.location.hash) {
      // console.log(window.location.hash);
      setActiveTab(false, window.location.hash.replace('#', ''));
    }

    var beersNav = $('#beersNav');

    var showAltTabs = new Waypoint({
      element: document.getElementById('pageHeader'),
      handler: function(direction) {
        if ( direction === 'down') {
          beersNav.removeClass('off-canvas');
        } else {
          beersNav.addClass('off-canvas');
        }
      }
    });

    var hideAltTabs = new Waypoint({
      element: document.getElementById('cta'),
      handler: function(direction) {
        if ( direction === 'down') {
          beersNav.addClass('off-canvas');
        } else {
          beersNav.removeClass('off-canvas');
        }
      },
      offset: function() {
        return Waypoint.viewportHeight() - this.element.clientHeight + 190;
      }
    });
  }
};

Sage.single_beer = {
  init: function() {
    // JavaScript to be fired on the single beer page
    $('#wordmark').on({
      mouseenter: function(e) {
        e.stopPropagation();
        $('.beer-banner').addClass('hover');
      },
      mouseleave: function(e) {
        e.stopPropagation();
        $('.beer-banner').removeClass('hover');
      }
    });

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      var $targetPane = $('.tab-pane' + $(e.target).attr('href'));
      var $prevPane = $('.tab-pane' + $(e.relatedTarget).attr('href'));
      var nextHeight = $targetPane.outerHeight();
      var prevHeight = $prevPane.outerHeight();
      var $tabContent = $targetPane.parent('.tab-content');

      $tabContent.height(prevHeight).animate({ height: nextHeight }, 200);
    });

    $beergallery = $('.beer-gallery');

    $beergallery.flickity({
      cellAlign: 'center',
      pageDots: false,
      wrapAround: true,
      imagesLoaded: true,
      arrowShape: 'M18.5,50L79.9,0l5.6,5.9L47.8,50,85.5,94.1,79.9,100Z',
      watchCSS: true
    });

    $beergallery.on('ready.flickity', function(event, index) {
      var type = $('.is-selected').data('type');

      $('.icon-' + type).addClass('active');
    });

    $beergallery.on('change.flickity', function(event, index) {
      var flkty = $(this).data('flickity');
      var type = $(flkty.selectedElement).data('type');

      $('.icon').removeClass('active');
      $('.icon-' + type).addClass('active');
    });

  }
};
